import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loading from "./components/Loading";

const Header = lazy(() => import("./components/Header"));
const Home = lazy(() => import("./components/Pages/Home"));
const ResearchPrograms = lazy(
  () => import("./components/Pages/ResearchPrograms")
);
const ContactUs = lazy(() => import("./components/Pages/ContactUs"));
const GICR = lazy(() => import("./components/Pages/GICR"));
const About = lazy(() => import("./components/Pages/About"));
const Investors = lazy(() => import("./components/Pages/Investors"));
const Team = lazy(() => import("./components/Pages/Team"));
const Media = lazy(() => import("./components/Pages/Media"));
const Publications = lazy(() => import("./components/Pages/Publications"));
const Footer = lazy(() => import("./components/Footer"));
const NotFound = lazy(() => import("./components/Pages/Page404"));
const ComingSoon = lazy(() => import("./components/Pages/ComingSoon"));

const MainRouter = () => {
  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/researchPrograms" element={<ResearchPrograms />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/gicr" element={<GICR />} />
          <Route path="/investors" element={<Investors />} />
          <Route path="/team" element={<Team />} />
          <Route path="/media" element={<Media />} />
          <Route path="/publications" element={<Publications />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </Suspense>
    </Router>
  );
};

export default MainRouter;
